import React from 'react';
import './SocialPopup.css';

const SocialPopup = () => {

  var myHTML = `<div class="socials">
    <a href="https://www.linkedin.com/in/riyush-ghimire-b136721a5" id="social-li" target="_blank"><i class="fab fa-linkedin-in"></i></a>
    <a href="https://app.hackthebox.com/profile/556183" id="social-htb" target="_blank"><i class="fas fa-cube"></i></a>
    <a href="https://github.com/richighimi" id="social-gh" target="_blank"><i class="fab fa-github"></i></a>
  </div>`

  myHTML = myHTML.replaceAll("CHANGE", window.location.href)
  console.log(window.location.href)
  return (
    <div dangerouslySetInnerHTML={{ __html: myHTML }} />
  )
}

export default SocialPopup;
