// import React from 'react';

// function HtbNotion() {
//   const htmlString = '<iframe src="https://v2-embednotion.com/a5c055f1e91040de8d3c2e1f65b7f389?v=f36606240e324f78967acff1db148bb3" style="width: 100%; height: 800px; border: 2px solid #ccc; border-radius: 10px; padding: none;"></iframe>';
//   // const htmlString = '<iframe src="https://notion.richighimi.com" style="width: 100%; height: 800px; border: 2px solid #ccc; border-radius: 10px; padding: none;"></iframe>';
  
//   return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
// }

// export default HtbNotion;

import React, { useEffect, useRef } from 'react';

const HtbNotion = () => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (iframeRef.current) {
        iframeRef.current.style.height = `${window.innerHeight}px`;
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <iframe
        ref={iframeRef}
        src="https://htb.richighimi.com/"
        title="Blog Content"
        style={{ width: '100%', height: '100vh', border: 'none' }}
        sandbox="allow-same-origin allow-scripts"
      ></iframe>
    </div>
  );
};

export default HtbNotion;