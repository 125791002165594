export const MenuItems = [
    {
        title:'home',
        url:'/',
    },   
    {
        title: 'writeups',
        url: '/writeups',
    },
    {
        title: 'blog',
        url: '/blog'
    },
    {
        title: 'whoami',
        url: '/resume',
    },
    {
        title: 'contact',
        url: '/contact',
    }
]