import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './Whoami.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Whoami = ({ url }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    const textLayers = document.querySelectorAll('.react-pdf__Page__annotations.annotationLayer');
    textLayers.forEach(layer => {
      layer.style.display = 'none';
    });
  };

  return (
    <div className="pdf-container">
      <Document file="https://richighimi.blob.core.windows.net/portfolio/lsdoi239810f12kljfsdf.pdf" onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1.5}/>
        ))}
      </Document>
    </div>
  );
};

export default Whoami;
