import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MenuItems } from './MenuItems';
import './Navbar.css';

class Navbar extends React.Component {
  state = {
    showMenu: false,
  };

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  };

  render() {
    return (
      <nav className="navbar-topbar">
        {/* Logo */}
        <Link to="/">

            <div className="logo-container">
                <img src="https://raw.githubusercontent.com/richighimi/portfolio-files/main/logo.svg" className="navbar-logo"/>
            </div>
        </Link>

        {/* Hamburger menu button */}
        <div className="navbar-menu-icon" onClick={this.toggleMenu}>
          <FontAwesomeIcon icon={this.state.showMenu ? faTimes : faBars} />
        </div>

        {/* Navigation items */}
        <ul className={this.state.showMenu ? 'navbar-show' : ''}>
          {MenuItems.map((item, index) => {
            return (
              <li key={index} className="navbar-item">
                <Link to={item.url} className="navbar-link">{item.title}</Link>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

export default Navbar;
