import React, { useState } from 'react';
import './Admin.css';

const Admin = () => {
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showNiceTry, setShowNiceTry] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));

    const username = e.target.username.value;
    const password = e.target.password.value;

    if (username === 'admin' && password === 'admin') {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setShowNiceTry(true);
      }, 10000);
    }
    setLoading(false);
  };

  return (
    <div className="admin-page">
      {showSuccess && (
        <div className="success-popup">
          <div className="loading-bar">
            <div className="loading-icon"></div>
            <p>Redirecting to the admin portal...</p>
          </div>
        </div>
      )}
      {showNiceTry && (
        <div className={`nice-try-popup flying-nice-try`}>
          <p className="flying">Nice try o7</p>
          <div className="youtube-window">
            <img
              src="https://raw.githubusercontent.com/richighimi/portfolio-files/main/rickroll-roll.gif"
              alt="Rick Roll"
              className={`flying-image`}
            />
            <audio autoPlay loop preload="auto">
              <source
                src="https://github.com/richighimi/portfolio-files/raw/main/Rick%20Rolled%20(Short%20Version).mp3"
                type="audio/mpeg"
              />
            </audio>
          </div>
        </div>
      )}
      {!showSuccess && !showNiceTry && (
        <form className={`login-form ${loading ? 'loading' : ''}`} onSubmit={handleSubmit}>
          <h2>Admin Login</h2>
          <input type="text" name="username" placeholder="Username" />
          <input type="password" name="password" placeholder="Password" />
          <button type="submit">Login</button>
        </form>
      )}
      {loading && (
        <div className="loading-bar">
          <div className="loading-icon"></div>
          <p>Authenticating...</p>
        </div>
      )}
    </div>
  );
};

export default Admin;
