import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';

//Router and switch modules
import {
  BrowserRouter as Router,
  Switch, Route
} from "react-router-dom"

//fontawesome for social tings
import "@fortawesome/fontawesome-svg-core/styles.css";

//script imports
import Home from './Home/Home.js'
import Navbar from './Navbar/Navbar.js';
import HtbNotion from './Writeups/HtbNotion';
import Whoami from './whomai/Whoami';
import SocialPopup from './SocialPopup/SocialPopup';
import Blog from './blog/Blog';
import Admin from './Admin/Admin';


const App = () => {



  return (
    <div>


      <Router>
        <Navbar />

        <Switch>

          <Route path='/writeups'>
            <HtbNotion />
          </Route>

          <Route path='/blog'>
            <Blog />
          </Route>

          <Route path='/resume'>
            <Whoami />
          </Route>

          <Route path='/admin'>
            <Admin />
          </Route>

          <Route exact path='/'>
            <Home />
          </Route>

          <Route path='*'>
            <Home />
          </Route>

        </Switch>
        
        <SocialPopup />

      </Router>
    </div>


  );
}

export default App;
