// // Blog.js
// import React from 'react';
// import './Blog.css';
// import { blogPosts } from './BlogItems';

// class Blog extends React.Component {
//     render() {
//         return (
//             <div>
//                 <h1 className="blog-title">//insert blogs here</h1>
//                 <div className="blog-posts">
//                     {blogPosts.map(post => (
//                         <div key={post.id} className="blog-post">
//                             <h3 className="blog-post-title">{post.title}</h3>
//                             <p className="blog-post-content">{post.content}</p>
//                             <img src={post.imageUrl} alt={post.title} className="blog-post-image" />
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         );
//     }
// }

// export default Blog;

import React, { useEffect, useRef } from 'react';

const Blog = () => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (iframeRef.current) {
        iframeRef.current.style.height = `${window.innerHeight}px`;
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <iframe
        ref={iframeRef}
        src="https://blog.richighimi.com/"
        title="Blog Content"
        style={{ width: '100%', height: '100vh', border: 'none' }}
        sandbox="allow-same-origin allow-scripts"
      ></iframe>
    </div>
  );
};

export default Blog;

