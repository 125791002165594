import React from 'react';

const Home = () => {

    return (
        <div className="App">
            <header className="App-header">
                <img src="https://raw.githubusercontent.com/richighimi/portfolio-files/main/brownrobo.png" className="App-logo" alt="logo" />
                <p>
                    still in the works...
                </p>
            </header>
        </div>
    )
}

export default Home